import { Environment } from '@services/environment/environment.types'

export var environment: Environment = {
  production: false,
  appName: 'Mobila Doktorn - Patient',
  // apiUrl: 'https://develop.pwa.mobiladoktorn.se/md-patient-api', // For local development
  // apiUrl: 'http://localhost:4203/md-patient-api',
  // apiUrl: 'http://localhost:4203/md_patient_api_war_exploded',
  // Replace the apiUrl with the one below before pushing
  // apiUrl: 'https://pwa.mobiladoktorn.se/md-patient-api',
  apiUrl: 'https://pwa.mobiladoktorn.se/md-patient-api',
  uniqueIdentifier: 'MDP-DEV',
  userIdleTimeout: 120,
  language: 'swe',
  contactDetails: {
    companyName: 'Docteum AB',
    companyNumber: '559115-4033',
    phone: '010-179 87 00',
    mobilDoktornPageUrl: 'https://www.mobiladoktorn.se',
    emailAddress: 'contact@mobiladoktorn.se',
    facebookUrl: 'https://www.facebook.com/MobilaDoktorn/',
    instagramUrl: 'https://www.instagram.com/mobiladoktorn/',
    twitterUrl: 'https://twitter.com/mobiladoktorn',
  },
}
// "target": "https://pwa.mobiladoktorn.se",
